<template>
  <div id="home">
    <el-container>
      <el-aside width="200px">
        <div class="left">
          <div class="logo">
            <div>
              <!-- <img style="width: 58px; height: 33px" src="../../public/image/logo.png" />
              <br />
              <img style="width: 52px; height: 32px" src="../../public/image/卷屋.png" alt="" /> -->
              <img style="width: 100%; height: 100%" src="../../public/image/logo.jpg" alt="">
            </div>
          </div>
          <el-menu @select="pathNmae" :default-active="this.path_name" class="el-menu-vertical-demo"
            :unique-opened="true">
            <el-submenu v-if='userRole == 1 || userRole == 0' index="1">
              <template slot="title">
                <div class="icon GroupManagement"></div>
                <span>群管理</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="CoreConfiguration" @click="puthName2('CoreConfiguration')">
                  <i class="el-icon-setting icon-style"></i>
                  核心配置</el-menu-item>
                <el-menu-item index="GroupData" @click="puthName2('GroupData')">
                  <i class="el-icon-view icon-style"></i>
                  群数据查看</el-menu-item>
                <!-- <el-menu-item index="1-2">选项2</el-menu-item> -->
              </el-menu-item-group>
            </el-submenu>
            <el-submenu v-if='userRole == 1' index="2">
              <template slot="title">
                <div class="icon commodity"></div>
                <span>团长 </span>
              </template>
              <el-menu-item-group>
                <el-menu-item v-if='userRole == 1' index="HeadOfMission" @click="puthName2('HeadOfMission')">
                  团长</el-menu-item>
                <el-menu-item v-if='userRole == 1' index="ConfigureGroupLeader"
                  @click="puthName2('ConfigureGroupLeader')">
                  配置群</el-menu-item> </el-menu-item-group>
            </el-submenu>
            <!-- <el-menu-item index="2">
              <div class="icon commodity"></div>
              <span slot="title">商品 </span>
            </el-menu-item> -->
            <!-- <el-submenu index="3">
              <template slot="title">
                <div class="icon orderForGoods"></div>
                <span>订单</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="3-1">选项1</el-menu-item>
                <el-menu-item index="3-2">选项2</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="4">
              <template slot="title">
                <div class="icon inventory"></div>
                <span>库存</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="4-1">选项1</el-menu-item>
                <el-menu-item index="4-2">选项2</el-menu-item>
              </el-menu-item-group>
            </el-submenu>
            <el-submenu index="5">
              <template slot="title">
                <div class="icon operate"></div>
                <span>运营</span>
              </template>
              <el-menu-item-group>
                <el-menu-item index="5-1">选项1</el-menu-item>
                <el-menu-item index="5-2">选项2</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
            <!-- <el-submenu index="6">
              <template slot="title">
                <div class="icon commodity"></div>
                <span>团长 </span>
              </template>
              <el-menu-item-group>
                <el-menu-item @click="puthName2('Account')" index="Account">团长</el-menu-item>
                <el-menu-item @click="puthName2('Account_balance')" index="Account_balance">配置群</el-menu-item>
              </el-menu-item-group>
            </el-submenu> -->
            <!-- <el-menu-item v-if='userRole == 1' index="ConfigureGroups" @click="puthName2('ConfigureGroups')">
              <i class="el-icon-user" style="font-weight: 600;color: #5863f6;"></i>
              <span slot="title">配置群 </span>
            </el-menu-item> -->
            <el-menu-item v-if='userRole == 1' index="user" @click="puthName2('user')">
              <i class="el-icon-user" style="font-weight: 600;color: #5863f6;"></i>
              <span slot="title">用户管理</span>
            </el-menu-item>
            <el-menu-item v-if='userRole == 3' index="HeadUser" @click="puthName2('HeadUser')">
              <i class="el-icon-user" style="font-weight: 600;color: #5863f6;"></i>
              <span slot="title">团长</span>
            </el-menu-item>
          </el-menu>
        </div>
      </el-aside>
      <el-container class="right">
        <el-header>
          <div class="head">

            <div class="user">
              <img style="width: 22px; height: 22px" src="../../public//image/提醒.png" alt="" />
              <div class="headSculpture"></div>
              <el-dropdown @command="handleCommand">
                <span class="el-dropdown-link">
                  {{ username }}<i class="el-icon-arrow-down el-icon--right"></i>
                </span>
                <el-dropdown-menu slot="dropdown">
                  <el-dropdown-item command="change_password">修改账号密码</el-dropdown-item>

                  <el-dropdown-item command="log_out">退出登陆</el-dropdown-item>
                </el-dropdown-menu>
              </el-dropdown>
            </div>
          </div>
        </el-header>
        <el-main>
          <div class="content">
            <router-view />
          </div>
        </el-main>
      </el-container>
    </el-container>

    <el-dialog title="修改密码" :visible.sync="showDialog" width="30%">
      <el-form :model="passwordForm" ref="passwordForm" :rules="rules" label-width="80px">
        <el-form-item label="新账号">
          <el-input v-model="passwordForm.newAccount"></el-input>
        </el-form-item>
        <el-form-item label="新密码" prop="newPassword">
          <el-input v-model="passwordForm.newPassword" autocomplete="off" type="password" clearable></el-input>
        </el-form-item>
        <el-form-item label="原密码" prop="oldPassword">
          <el-input v-model="passwordForm.oldPassword" autocomplete="off" type="password" clearable></el-input>
        </el-form-item>
      </el-form>
      <span slot="footer" class="dialog-footer">
        <el-button @click="showDialog = false">取消</el-button>
        <el-button type="primary" @click="changePassword" :loading="loading">确定修改</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
  import commodity from "./commodity/commodity.vue";
  import { logout, changePassword } from "../api/user.js";

  export default {
    name: "HomeView",
    components: {
      commodity,
    },
    mounted () {
      let path = sessionStorage.getItem('path')
      if (path) {
        this.path_name = path
      }
    },
    data () {
      return {
        showDialog: false, // 控制对话框的显示
        loading: false,
        passwordForm: { // 存储用户输入的密码
          oldPassword: '',
          newPassword: '',
          newAccount: '',
        },
        rules: {
          newAccount: [{ required: true, message: '请输入新账号', trigger: 'blur' }],
          newPassword: [
            { required: true, message: '请输入新密码', trigger: 'blur' },
            { min: 6, message: '密码长度不能小于6位', trigger: 'blur' }
          ],
          oldPassword: [{ required: true, message: '请输入原密码', trigger: 'blur' }],
        },
        value: "",
        options: [],
        path_name: "CoreConfiguration",
        username: sessionStorage.getItem('userAccount') || '',
        userRole: sessionStorage.getItem('userRole') || ''
      };
    },
    methods: {
      pathNmae (key, keyPath) {
        this.path_name = key;
      },
      puthName2 (name) {
        sessionStorage.setItem('path', name)
        this.$router.push("/home/" + name);
      },
      handleCommand (command) {
        if (command == "log_out") {
          logout().then(() => {
          }).catch(err => {
            this.$message.error(err);
          })
          this.$router.push("/login");
          localStorage.clear();
          sessionStorage.clear();
        } else if (command == "change_password") {
          // 显示修改密码对话框
          this.showDialog = true;
          this.passwordForm.newAccount = this.username
        }
      },
      changePassword () {
        changePassword(this.passwordForm)
          .then((res) => {
            this.$message({
              message: res.data,
              type: "success",
            });
            this.loading = false;
            this.showDialog = false;
            this.username = this.passwordForm.newAccount //更新账号显示
          })
          .catch((err) => {
            this.$message.error(err.description);
          });
      },
    },
  };
</script>
<style lang="less">
  #home {
    display: flex;
    height: 100%;

    .left {
      // width: 210px;
      height: 100%;
      // background-color: #fff;

      .logo {
        width: 100%;
        height: 110px;
        background: linear-gradient(to left,
            rgba(81, 88, 243, 1),
            rgba(109, 133, 255, 1));
        display: flex;
        justify-content: center;
        align-items: center;
        border-bottom-right-radius: 30px;
      }

      .el-menu {
        height: calc(100% - 110px);
        border-right: none;
      }

      .icon {
        display: inline-block;
        width: 16px;
        height: 16px;
        background-size: 100% 100%;
        margin: 0px 9px 0 5px;
      }

      .GroupManagement {
        background-image: url(../../public/image/群管理.png);
      }

      .commodity {
        background-image: url(../../public/image/商品.png);
      }

      .orderForGoods {
        background-image: url(../../public/image/订单.png);
      }

      .inventory {
        background-image: url(../../public/image/库存.png);
      }

      .operate {
        background-image: url(../../public/image/运营.png);
      }
    }

    .right {
      flex: 1;
      height: 100%;
      box-sizing: border-box;
      font-size: 14px;

      .head {
        height: 100%;
        background-color: #fff;
        // display: flex;
        // justify-content: space-between;
        // align-items: center;

        .user {
          float: right;
          height: 100%;
          display: flex;
          align-items: center;

          .headSculpture {
            width: 32px;
            height: 32px;
            border-radius: 50%;
            background-color: aqua;
            display: inline-block;
            margin: 0 15px 0 50px;
          }

          .el-dropdown {
            margin-right: 50px;

            .el-dropdown-link {
              cursor: pointer;
              color: #409eff;
            }

            .el-icon-arrow-down {
              font-size: 12px;
            }
          }
        }
      }

      .content {
        // height: calc(100% - 115px);
        padding: 20px 20px 0px;
        overflow-y: auto;

        .box {
          width: 100%;
          // height: 100%;
          border-radius: 10px;
          background-color: #fff;
          padding: 20px;
          box-sizing: border-box;
        }

        .box1 {
          padding: 20px 20px 0px !important;
        }

        .title {
          border-left: 3px solid rgba(84, 91, 245, 1);
          padding-left: 20px;
          color: rgba(84, 91, 245, 1);
          font-weight: 600;
        }
      }

      .el-dialog {
        width: 1000px;
      }

      .modify_head_sculpture {
        display: flex;
        height: 200px;

        .modify_left {
          width: 30%;

          .avatar-uploader .el-upload {
            border: 1px dashed #d9d9d9;
            border-radius: 6px;
            cursor: pointer;
            position: relative;
            overflow: hidden;
          }

          .avatar-uploader .el-upload:hover {
            border-color: #409EFF;
          }

          .avatar-uploader-icon {
            font-size: 28px;
            color: #8c939d;
            width: 178px;
            height: 178px;
            line-height: 178px;
            text-align: center;
          }

          .avatar {
            width: 178px;
            height: 178px;
            display: block;
          }
        }

        .modify_right {
          flex: 1;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
          grid-template-rows: 50px 50px;

          span {
            display: inline-block;
            width: 70px;
            text-align: right;
          }

          .time {
            .el-input__prefix {
              width: 0px;
            }
          }
        }
      }
    }

    .el-header {
      height: 85px !important;
      padding: 0px;
    }

    .el-main {
      padding: 0px;
    }


  }
</style>