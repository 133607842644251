<template>
  <div class="commodity">
    <div style="position: absolute;top: 30px;font-size: 20px;">
      <span style="font-weight: 600; margin-right: 10px;">群id</span>
      <el-select style="width: 200px;" size="mini" v-model="group_id" placeholder="请选择" @change="getGroupId">
        <el-option v-for="item in group_list" :key="item.group_wxid" :label="item.group_name" :value="item.group_id">
        </el-option>
      </el-select>
    </div>
    <div class="box">
      <div class="title">核心配置
        <div style="float: right;">
          <el-button style="margin: 0;" size="mini" type="primary" @click="checkin()">刷新</el-button>
          <el-button style="margin: 0;margin-left: 30px;" size="mini" type="warning" @click="modify">修改</el-button>
        </div>
      </div>
      <div class="box clock_in_instruction" v-if="clock_in_data">
        <div v-if="clock_in_data.other_configs">
          <strong>打卡配置:<el-button type="success" size="mini" @click="add_command_list">添加</el-button></strong>
          <div class="box box1" v-for="(item, index) in clock_in_data.other_configs.command_list">
            发送指令
            <!-- <el-select style="width: 200px;" size="mini" multiple clearable allow-create default-first-option
              v-model="item.command_name" placeholder="请选择">
              <el-option v-for="item in clock_in_instruction_list" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select> -->
            <el-select style="min-width: 300px;" v-model="item.command_name" size="mini" multiple filterable
              allow-create default-first-option placeholder="请选择文章标签">
              <el-option v-for="item in clock_in_instruction_list" :key="item.value" :label="item.label"
                :value="item.value">
              </el-option>
            </el-select>
            <!-- <el-input placeholder="" v-model="item.command_name" clearable>
            </el-input> -->
            打卡积分+<el-input-number v-model="item.check_in_points" @change="handleChange" :min="0" :max="1000" label=""
              size="mini"></el-input-number>
            不打卡积分-<el-input-number v-model="item.deduction_points" @change="handleChange" :min="0" :max="1000" label=""
              size="mini"></el-input-number>
            允许打卡时间段
            <el-time-select v-model="item.start_time" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }"
              size="mini" placeholder="" />
            至
            <el-time-select v-model="item.end_time" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }"
              size="mini" placeholder="" />
          </div>
        </div>
        <div style="margin-top: 20px" v-if="clock_in_data">
          <strong>休息日:</strong>
          <div class="box box1" v-if="clock_in_data.other_configs">
            每周允许休息
            <el-select style="width: 100px;" size="mini"
              v-model="clock_in_data.other_configs.allowed_vacation_days_per_week" placeholder="请选择">
              <el-option v-for="item in 7" :key="item" :label="item" :value="item">
              </el-option>
            </el-select>
            天
          </div>
        </div>
        <div style="margin-top: 20px">
          <strong>赛季模式</strong>
          <el-switch v-model="value1"> </el-switch>
          <div class="box box1">
            每
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini"
              style="margin: 0px 5px" />
            周一个赛季，每赛季继承上赛季
            <el-select style="margin: 0px 5px" size="mini" v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            电池
          </div>
        </div>
        <div style="margin-top: 20px; display: flex">
          <span style="display: table-cell; vertical-align: top">
            <strong>文案编辑：</strong>
          </span>
          <el-input type="textarea" autosize placeholder="请输入内容" v-model="textarea1" size="mini">
          </el-input>
        </div>
      </div>
    </div>
    <div class="box" style="margin-top: 20px">
      <div class="title">违规处理 </div>
      <div>
        <div>
          <div class="box box1">
            未打卡提醒：
            <div style="margin-top: 20px">
              每天
              <el-time-select v-model="value" :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }" size="mini" placeholder="" />
              提醒未打卡成员打卡，提醒文案@小李
              <el-input style="width: 50%" size="mini" v-model="input"
                placeholder="以上成员未打卡请即时打卡，回复“取消”取消提功能，回复“提醒”添加提醒功能"></el-input>
            </div>
            <div style="margin-top: 20px">
              每天
              <el-time-select v-model="value" :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }" size="mini" placeholder="" />
              提醒积分&lsaquo;=
              <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini"
                style="margin: 0px 5px" />
              提醒文案@小李
              <el-input style="width: 50%" size="mini" v-model="input" placeholder="积分小于5哦，如果今天还不打卡，将会关进小黑屋"></el-input>
            </div>
            <div style="margin-top: 20px">
              积分&lsaquo;0时将无法打卡,此时提醒文案：
              <el-input style="width: 50%" size="mini" v-model="input" placeholder="积分<0无法打卡请联系管理员复活"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- <div class="box">
      <div class="title">打卡模式</div>
      <div class="box">
        <div>
          <strong>单打卡</strong>
          <el-switch v-model="value1"> </el-switch>
          <div class="box box1">
            打卡积分+<el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label=""
              size="mini"></el-input-number>
            不打卡积分-<el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label=""
              size="mini"></el-input-number>
            打卡截止时间
            <el-time-select v-model="value" :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '23:00',
            }" size="mini" placeholder="">
            </el-time-select>
          </div>
        </div>
        <div style="margin-top: 20px">
          <strong>多打卡</strong>
          <el-switch v-model="value1"> </el-switch>
          <div class="box box1">
            发送指令&nbsp;
            <el-button plain size="mini">早起</el-button>
            打卡积分+
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            不打卡积分-
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            允许打卡时间段
            <el-time-select v-model="value" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }" size="mini"
              placeholder="" />
            至
            <el-time-select v-model="value" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }" size="mini"
              placeholder="" />
          </div>
          <div class="box box1">
            发送指令&nbsp;
            <el-button plain size="mini">学习</el-button>
            打卡积分+
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            不打卡积分-
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            允许打卡时间段
            <el-time-select v-model="value" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }" size="mini"
              placeholder="" />
            至
            <el-time-select v-model="value" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }" size="mini"
              placeholder="" />
          </div>
          <div class="box box1">
            发送指令&nbsp;
            <el-button plain size="mini">单词</el-button>
            打卡积分+
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            不打卡积分-
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            允许打卡时间段
            <el-time-select v-model="value" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }" size="mini"
              placeholder="" />
            至
            <el-time-select v-model="value" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }" size="mini"
              placeholder="" />
          </div>
          <div class="box box1">
            发送指令&nbsp;
            <el-button plain size="mini">运动</el-button>
            打卡积分+
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            不打卡积分-
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            允许打卡时间段
            <el-time-select v-model="value" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }" size="mini"
              placeholder="" />
            至
            <el-time-select v-model="value" :picker-options="{ start: '00:00', step: '01:00', end: '23:00' }" size="mini"
              placeholder="" />
          </div>
        </div>
        <div style="margin-top: 50px">
          <strong>按天数</strong>
          <el-switch v-model="value1"> </el-switch>
          <div class="box box1">
            打卡积分+
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            不打卡积分-
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            每周允许休息
            <el-select size="mini" v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            超过一天积分-
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini" />
            打卡截止时间
            <el-time-select v-model="value" :picker-options="{
              start: '00:00',
              step: '01:00',
              end: '23:00',
            }" size="mini" placeholder="" />
          </div>
        </div>
        <div style="margin-top: 50px">
          <strong>赛季模式</strong>
          <el-switch v-model="value1"> </el-switch>
          <div class="box box1">
            每
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini"
              style="margin: 0px 5px" />
            周一个赛季，每赛季继承上赛季
            <el-select style="margin: 0px 5px" size="mini" v-model="value" placeholder="请选择">
              <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
            </el-select>
            电池
          </div>
        </div>
        <div style="margin-top: 50px; display: flex">
          <span style="display: table-cell; vertical-align: top">
            <strong>文案编辑：</strong>
          </span>
          <el-input type="textarea" autosize placeholder="请输入内容" v-model="textarea1" size="mini">
          </el-input>
        </div>
        <div style="margin-top: 50px">
          <strong>连续打卡赠送免卡机会</strong>
          <el-switch v-model="value1"> </el-switch>
          <div class="box box1">
            连续打卡
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini"
              style="margin: 0px 5px" />
            天,赠送
            <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini"
              style="margin: 0px 5px" />
            免卡
          </div>
        </div>
      </div>
    </div> -->
    <div class="box" style="margin-top: 20px">
      <div class="title">提醒功能 <el-switch v-model="value1"> </el-switch></div>
      <div>
        <div>
          <div class="box box1">
            未打卡提醒：
            <div style="margin-top: 20px">
              每天
              <el-time-select v-model="value" :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }" size="mini" placeholder="" />
              提醒未打卡成员打卡，提醒文案@小李
              <el-input style="width: 50%" size="mini" v-model="input"
                placeholder="以上成员未打卡请即时打卡，回复“取消”取消提功能，回复“提醒”添加提醒功能"></el-input>
            </div>
            <div style="margin-top: 20px">
              每天
              <el-time-select v-model="value" :picker-options="{
                start: '00:00',
                step: '01:00',
                end: '23:00',
              }" size="mini" placeholder="" />
              提醒积分&lsaquo;=
              <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini"
                style="margin: 0px 5px" />
              提醒文案@小李
              <el-input style="width: 50%" size="mini" v-model="input" placeholder="积分小于5哦，如果今天还不打卡，将会关进小黑屋"></el-input>
            </div>
            <div style="margin-top: 20px">
              积分&lsaquo;0时将无法打卡,此时提醒文案：
              <el-input style="width: 50%" size="mini" v-model="input" placeholder="积分<0无法打卡请联系管理员复活"></el-input>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="box" style="margin-top: 20px">
      <div class="title">管理员指令 </div>
      <div>
        <div class="box box1" style="display: flex">
          <div style="margin-right: 10px; margin-bottom: 10px" v-for="(item, index) in admin_instruct" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="box" style="margin-top: 20px">
      <div class="title">客户指令 </div>
      <div>
        <div class="box box1" style="display: flex">
          <div style="margin-right: 10px; margin-bottom: 10px" v-for="(item, index) in user_instruct" :key="index">
            {{ item }}
          </div>
        </div>
      </div>
    </div>
    <div class="box" style="margin-top: 20px">
      <div class="title">定时消息 <el-switch v-model="value1"> </el-switch></div>
      <div class="box box1">
        <el-checkbox-group v-model="checkList">
          <el-checkbox label="周一"></el-checkbox>
          <el-checkbox label="周二"></el-checkbox>
          <el-checkbox label="周三"></el-checkbox>
          <el-checkbox label="周四"></el-checkbox>
          <el-checkbox label="周五"></el-checkbox>
          <el-checkbox label="周六"></el-checkbox>
          <el-checkbox label="周天"></el-checkbox>
        </el-checkbox-group>
      </div>
      <div class="box">
        每天
        <el-time-select v-model="value" :picker-options="{
          start: '00:00',
          step: '01:00',
          end: '23:00',
        }" size="mini" placeholder="" />
        在群内随机发送消息
        <el-input style="width: 50%" size="mini" v-model="input" placeholder="距离考研还有<考研倒计时>，新的一天，继续加油呀！" />
      </div>
      <div class="title">每日一句<el-switch v-model="value1"> </el-switch></div>
      <div class="box" style="display: flex">
        <div style="margin-right: 5px">
          每天
          <el-time-select v-model="value" :picker-options="{
            start: '00:00',
            step: '01:00',
            end: '23:00',
          }" size="mini" placeholder="" />
          在群内随机发送消息
        </div>
        <el-input type="textarea" autosize style="width: 50%" size="mini" v-model="input"
          placeholder="距离考研还有<考研倒计时>，新的一天，继续加油呀！" />
      </div>
    </div>
    <div class="box" style="margin-top: 20px">
      <div class="title">邀请裂变 <el-switch v-model="value1"> </el-switch></div>
      <div class="box box1">
        成员每邀请1人进群并完成首次打卡积分+
        <el-input-number v-model="num" @change="handleChange" :min="0" :max="1000" label="" size="mini"
          style="margin: 0px 5px" />
        分
      </div>
      <div class="box" style="display: flex">
        文案：
        <el-input type="textarea" autosize style="width: 50%" size="mini" v-model="input" placeholder="@小高邀请成功，奖励积分5" />
      </div>
    </div>
    <div class="box" style="margin-top: 20px">
      <div class="title">群收费版 </div>
      <div class="box box1">客户艾特机器人付费，机器人自动回复收款码</div>
      <div class="box box1" style="display: flex">
        <div style="margin-right: 5px">
          可免费体验
          <el-select style="margin: 0 5px" size="mini" v-model="value" placeholder="请选择">
            <el-option v-for="item in options" :key="item.value" :label="item.label" :value="item.value" />
          </el-select>
          超过将无法打卡，打卡时提醒文案：
        </div>
        <el-input style="width: 50%" size="mini" v-model="input" placeholder="体验期结束，请联系管理员升级" />
      </div>
      <div class="box box1" style="display: flex">
        文案编辑：
        <el-input type="textarea" autosize style="width: 50%" size="mini" v-model="input" placeholder="" />
      </div>
      <div class="box">额外管理员指令：查看体验结束成员</div>
    </div>
    <div style="height: 50px"></div>
  </div>
</template>
<script>
  import { groupOfManagement, Checkin, modificationCheckin } from "../../api/Management";

  export default {
    name: "commodity",
    data () {
      return {
        value1: false,
        num: 0,
        value: "",
        rest_days: [
          {
            value: "1",
            label: "1",
          },
          {
            value: "2",
            label: "2",
          },
          {
            value: "3",
            label: "3",
          },
          {
            value: "4",
            label: "4",
          },
          {
            value: "5",
            label: "5",
          },
          {
            value: "6",
            label: "6",
          },
          {
            value: "7",
            label: "7",
          }
        ],
        options: [],
        value2: "",
        options1: [
          {
            value: "选项1",
            label: "10%",
          },
          {
            value: "选项2",
            label: "20%",
          },
          {
            value: "选项3",
            label: "30%",
          },
          {
            value: "选项4",
            label: "40%",
          },
          {
            value: "选项5",
            label: "50%",
          },
          {
            value: "选项6",
            label: "60%",
          },
          {
            value: "选项7",
            label: "70%",
          },
          {
            value: "选项8",
            label: "80%",
          },
          {
            value: "选项9",
            label: "90%",
          },
          {
            value: "选项10",
            label: "100%",
          },
        ],
        textarea1: "",
        input: "",
        admin_instruct: [
          "@小李电池+x",
          "@小李积分-1",
          "@小李冻结",
          "@小李复活",
          "@机器人全员+x",
          "@加入全员-x",
          "@机器人查看排行榜",
          "@机器人查看负分成员",
          "@机器人艾特负分成员",
          "@机器人踢出负分成员",
        ],
        user_instruct: ["打卡", "查看积分", "chatgpt问答"],
        group_list: [],
        group_id: '',
        checkList: [],
        clock_in_instruction_list: [
          {
            value: '1',
            label: '早起'
          },
          {
            value: '2',
            label: '早安'
          },
          {
            value: '3',
            label: '早上好'
          },
          {
            value: '4',
            label: '单词'
          },
          {
            value: '5',
            label: '运动'
          }
        ],
        clock_in_instruction: [],
        clock_in_data: {
          clock_in_instruction: '',
          add: '',
          reduce: '',
          rest: '',
        }
      };
    },
    created () {
      this.groupOf_Management()
    },
    methods: {
      handleChange (value) {
        console.log(value);
      },
      groupOf_Management () {
        groupOfManagement().then(res => {
          this.group_list = res
          if (this.group_list != 0) {
            this.getGroupId(this.group_list[0].group_id)
            this.group_id = this.group_list[0].group_id
          }
        }).catch(err => {
          this.$message.error(err);
        })
      },
      getGroupId (value) {
        this.group_id = value
        this.checkin()
      },
      checkin () {
        Checkin(this.group_id).then(res => {
          console.log(res);
          this.clock_in_data = res
          // this.clock_in_data.other_configs = JSON.parse(res.other_configs.replace(/\\/g, ""))
          this.clock_in_data.whitelist = JSON.parse(res.whitelist.replace(/\\/g, ""))
          this.clock_in_data.blacklist = JSON.parse(res.blacklist.replace(/\\/g, ""))
          this.clock_in_data.other_configs.command_list.forEach(item => {
            item.command_name = item.command_name.split('/')
          });
        }).catch(err => {
          this.$message.error(err);
        })
      },
      add_command_list () {
        this.clock_in_data.other_configs.command_list.push({
          check_in_points: 0,
          command_name: [],
          deduction_points: 0,
          end_time: '',
          start_time: ''
        })
      },
      modify () {
        this.clock_in_data.other_configs.command_list.forEach(item => {
          if (item.command_name.length != 0) {
            item.command_name = item.command_name.join('/')
          }
        })
        if (this.clock_in_data.whitelist.length != 0) {
          this.clock_in_data.whitelist = '[' + this.clock_in_data.whitelist.join() + ']'
        } else {
          this.clock_in_data.whitelist = "[]"
        }
        if (this.clock_in_data.blacklist.length != 0) {
          this.clock_in_data.blacklist = '[' + this.clock_in_data.blacklist.join() + ']'
        } else {
          this.clock_in_data.blacklist = "[]"
        }
        // this.clock_in_data.other_configs = JSON.stringify(this.clock_in_data.other_configs)
        modificationCheckin(this.clock_in_data).then(res => {
          this.checkin()

        }).catch(err => {
          this.$message.error(err);
        })
      },
    },
  };
</script>
<style lang="less">
  .commodity {
    .el-switch {
      margin-left: 10px;
    }

    .el-button {
      width: 100px;
      margin-left: 5px;
      margin-right: 45px;
    }

    .el-input-number,
    .el-select {
      width: 100px;
      margin-right: 30px;
      margin-left: 5px;
    }

    .el-date-editor {
      width: 100px !important;
      margin-left: 5px;
    }

    .clock_in_instruction {
      .el-select {
        width: 150px;
        margin-right: 0px;
        margin-left: 0px;
      }

      .el-input-number {
        margin-right: 0px;
        margin-left: 0px;
      }

    }

    .el-textarea {
      width: 50%;
      display: inline-block;
    }

    .el-checkbox__label {
      float: left;
    }

    .el-checkbox__input {
      margin-left: 8px;
    }
  }
</style>