import axios from 'axios'

import {Notification } from 'element-ui';

const request = axios.create({
  timeout: 10000
})
request.interceptors.request.use(
  config => {
    const token = sessionStorage.getItem('token');
    if (token) {
      config.headers['token'] = token;
    }
    return config;
  },
  error => Promise.error(error)
);
request.interceptors.response.use(
  response => {
    const code = response.data.code || 200 ;//若未设置默认成功状态
    if (code !== 0 && code !== 200) {
      return Promise.reject(response.data);
    } else {
      return response.data
    }
  },
  error => {
    return Promise.reject(error);
  }
)

export default request

