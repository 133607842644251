import axios from '../util/axios1.js'
import { API_BASE_URL } from './api.js';

// let axios = request
export function login (data) {
  return axios({
    url: `${API_BASE_URL}/user/login`,
    method: 'post',
    data: data,
  })
}

export function register (data) {
  return axios({
    url: `${API_BASE_URL}/user/register`,
    method: 'post',
    data,
  })
}

export function userSearch () {
  return axios({
    url: `${API_BASE_URL}/user/search`,
    method: 'get',
  })
}

export function userDelete (id) {
  return axios({
    url: `${API_BASE_URL}/user/delete/` + id,
    method: 'DELETE',
  })
}

export function userCurrent () {
  return axios({
    url: `${API_BASE_URL}/user/current`,
    method: 'get',
  })
}

export function logout () {
  return axios({
    url: `${API_BASE_URL}/user/logout`,
    method: 'post',
  })
}

export function userUpdate (data) {
  return axios({
    url: `${API_BASE_URL}/user/update`,
    method: 'put',
    data,
    // headers: {
    //   'Content-Type': 'multipart/form-data',
    // },
  })
}

export function getHeadOfMission () {
  return axios({
    url: `${API_BASE_URL}/user/headOfMission/search`,
    method: 'get',
  })
}

//修改账号密码
export function changePassword (data) {
  return axios({
    url: `${API_BASE_URL}/user/update/password`,
    method: 'post',
    data
  })
}
