import axios from '../util/axios1.js'
import { API_BASE_URL } from './api.js';

export function groupOfManagement () {
  return axios({
    url: `${API_BASE_URL}/plugin/groupofmanagement`,
    method: 'get',
  })
}

export function Checkin (id) {
  return axios({
    url: `${API_BASE_URL}/plugin/checkin/` + id,
    method: 'get',
  })
}
export function modificationCheckin (data) {
  return axios({
    url: `${API_BASE_URL}/plugin/modificationcheckin`,
    method: 'post',
    data
  })
}

// 查询监测提醒
export function getMonitoringReminder (id) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/getMonitoringReminder/` + id,
    method: 'get',
  });
}
// 修改监测提醒
export function setMonitoringReminder (data) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/setMonitoringReminder`,
    method: 'post',
    data
  });
}
// 查询积分过小提醒
export function getPointsTooSmallReminder (id) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/getPointsTooSmallReminder/` + id,
    method: 'get',
  });
}
// 修改积分过小提醒
export function setPointsTooSmallReminder (data) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/setPointsTooSmallReminder`,
    method: 'post',
    data
  });
}

// 倒计时查询
export function getTimingMessage (id) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/getCountdown/` + id,
    method: 'get',
  });
}
// 倒计时查询修改
export function setTimingMessage (data) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/setCountdown`,
    method: 'post',
    data
  });
}
// 每日一句查询
export function getADailySentence (id) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/getADailySentence/` + id,
    method: 'get',
  });
}
// 每日一句修改
export function setADailySentence (data) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/setADailySentence`,
    method: 'post',
    data
  });
}
// 监测扣分查询
export function getMonitoringPointsDeduction (id) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/getMonitoringPointsDeduction/` + id,
    method: 'get',
  });
}
// 监测扣分修改
export function setMonitoringPointsDeduction (data) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/setMonitoringPointsDeduction`,
    method: 'post',
    data
  });
}
// 倒计时类型查询
export function getCountdownType () {
  return axios({
    url: `${API_BASE_URL}/timingMessage/getCountdownType`,
    method: 'get',
  });
}

// 监测扣分修改
export function addCountdownType (data) {
  return axios({
    url: `${API_BASE_URL}/timingMessage/addCountdownType`,
    method: 'post',
    data
  });
}
