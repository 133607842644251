import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

Vue.use(VueRouter)

const originalPush = VueRouter.prototype.push
VueRouter.prototype.push = function push (location) {
  return originalPush.call(this, location).catch(err => err)
}
const routes = [
  {
    path: '/home/',
    name: 'home',
    component: HomeView,
    children: [

      {
        path: 'commodity',
        name: 'commodity',
        component: () => import('@/views/commodity/commodity'),
        meta: { title: '商品' }
      },
      {
        path: 'Account_balance',
        name: 'Account_balance',
        component: () => import('@/views/recharge/Account_balance'),
        meta: { title: '账户余额' }
      },
      {
        path: 'Account',
        name: 'Account',
        component: () => import('@/views/recharge/Account'),
        meta: { title: '我的账户' }
      },
      {
        path: 'user',
        name: 'user',
        component: () => import('@/views/user/user'),
        meta: { title: '用户管理' }
      },
      {
        path: 'CoreConfiguration',
        name: 'CoreConfiguration',
        component: () => import('@/views/manage/CoreConfiguration'),
        meta: { title: '个人信息' }
      },
      {
        path: 'ConfigureGroups',
        name: 'ConfigureGroups',
        component: () => import('@/views/config/ConfigureGroups'),
        meta: { title: '配置群' }
      },
      {
        path: 'GroupData',
        name: 'GroupData',
        component: () => import('@/views/groupdata/GroupData'),
        meta: { title: '查看群数据' }
      },
      {
        path: 'HeadOfMission',
        name: 'HeadOfMission',
        component: () => import('@/views/headOfMission/HeadOfMission'),
        meta: { title: '团长' }
      },
      {
        path: 'ConfigureGroupLeader',
        name: 'ConfigureGroupLeader',
        component: () => import('@/views/headOfMission/ConfigureGroupLeader'),
        meta: { title: '团长配置群' }
      },
      {
        path: 'HeadUser',
        name: 'HeadUser',
        component: () => import('@/views/headOfMission/HeadUser'),
        meta: { title: '团长用户' }
      },

    ]
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('../views/AboutView.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login.vue')
  }
]

const router = new VueRouter({
  mode: 'hash',
  base: process.env.BASE_URL,
  routes
})
router.beforeEach((to, from, next) => {
  if (to.path == '/login') {
    next()
  } else if (to.path == '/') {
    next({ path: '/login' })
  } else {
    if (!sessionStorage.getItem('token')) {
      next({ path: '/login' })
    } else {
      next()
    }
  }
})
export default router
